<template>
  <div>
    <p>1. คลิกที่อัพโหลดวอลเปเปอร์</p>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_1.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom"
      style="max-width: 250px"
      @click="previewImage(1)"
    />
    <hr />
    <p>
      2. เลือกรูปภาพที่ต้องการอัพโหลด (ขนาดภาพต้องไม่เกิน 5MB) (ขนาดรูปแนะนำคือ 1284 กว้าง x 2278 ยาว)
      โดยสามารถเลือกได้หลายรูป พร้อมระบุรายละเอียดของแต่ละรูปดังนี้
    </p>
    <ul>
      <li>ชื่อของรูปภาพ</li>
      <li>รายละเอียดของรูปภาพ เช่น ส่งเสริมในทางด้านใด เน้นให้คนเกิดวันไหนใช้เป็นพิเศษ ฯลฯ</li>
      <li>ราคาของรูปภาพที่กำหนด ถ้าไม่ใส่หรือเป็น 0 จะปรับเป็นรูปฟรีอัตโนมัติ</li>
      <li>สีลายน้ำ สีตัวอักษร และการผูกดวงประจำวัน</li>
    </ul>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_2.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom"
      style="max-width: 800px"
      @click="previewImage(2)"
    />
    <hr />
    <p>
      3. ระบุรายละเอียดของวอลเปเปอร์ที่เป็นรูปหลักเพื่อแสดงหน้าหลักบนแอป เพื่อดึงดูดให้ผู้ใช้งานสนใจดาวน์โหลด
      หากมีลิ้งค์เว็บไซต์ หรือช่องยูทูปที่ต้องการให้ติดตามสามารถใส่ได้ จากนั้นเลือกราศี
      และฟ้อนต์ที่ต้องการให้แสดงบนวอลเปเปอร์
    </p>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_3.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom"
      style="max-width: 800px"
      @click="previewImage(3)"
    />
    <hr />
    <p>
      4. คลิกเพื่อเลือกรูปวอลเปเปอร์ที่ต้องการแสดงในหน้าหลักของแอปและจัดวางตำแหน่งของตัวอักษรบนภาพ พร้อมตั้งค่าขนาดต่าง
      ๆ ให้เหมาะสมกับภาพมากที่สุด
    </p>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_4.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom my-1"
      style="max-width: 800px"
      @click="previewImage(4)"
    />
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_5.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom my-1"
      style="max-width: 800px"
      @click="previewImage(5)"
    />
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_6.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom my-1"
      style="max-width: 800px"
      @click="previewImage(6)"
    />
    <hr />
    <p>
      5.เมื่อมาถึงหน้าพรีวิว สามารถกดดูรูปพรีวิว เพื่อดูภาพโดยรวมของวอลเปเปอร์ที่จะอัพโหลดได้
      หากต้องการแก้ไขให้กดย้อนกลับไป หากถูกใจแล้ว ให้กดบันทึกเพื่อทำการสร้างรูปวอลเปเปอร์นี้
    </p>
    <p class="text-danger">
      **การแก้ไขฟ้อนและสีของตัวอักษรในหน้านี้จะไม่ถูกนำไปใช้กับรูป เพราะเป็นแค่การสร้างรูปตัวอย่างเท่านั้น
    </p>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_7.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom my-1"
      style="max-width: 800px"
      @click="previewImage(7)"
    />
    <hr />
    <p>
      6.หลักจากดบันทึกแล้ว รูปวอลเปเปอร์จะไปอยู่ที่ "วอลเปเปอร์ทั้งหมด" และสถานะรีวิว จะเป็น รออนุมัติ
      ซึ่งต้องรอแอดมินตรวจสอบ หากสถานะรีวิวขึ้นเป็นสีเขียว
      หมายถึงได้รับการอนุมัติและรูปวอลเปเปอร์นี้ได้ขึ้นไปในแอปเพื่อให้ผู้ใช้ดาวน์โหลดได้แล้ว
    </p>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_wall_8.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom my-1"
      style="max-width: 200px"
      @click="previewImage(8)"
    />
  </div>
</template>

<script>
export default {
  methods: {
    previewImage(id = '') {
      this.$viewerApi({
        // eslint-disable-next-line import/no-dynamic-require, global-require
        images: [require(`@/assets/images/ongphra/how-to/upload_wall_${id}.webp`)],
        options: {
          navbar: false,
          title: false,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
