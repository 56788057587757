<template>
  <div>
    <p>1. คลิกที่อัพโหลดราศี</p>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_zodiac_1.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom"
      style="max-width: 250px"
      @click="previewImage(1)"
    />
    <hr />
    <p>
      2. อัปโหลดภาพราศีที่ต้องการนำไปใช้กับรูปวอลเปเปอร์
      จากนั้นระบุชื่อของแต่ละรูปราศี(เป็นชื่อที่ผู้ใช้งานจะเห็นในแอปตอนสร้างวอลเปเปอร์)
      และหมวดหมู่ของรูปที่อัพโหลด(เป็นชื่อที่สื่อให้เข้าใจตอนเลือกไปใช้สร้างรูปวอลเปเปอร์)
    </p>
    <b-img
      :src="require('@/assets/images/ongphra/how-to/upload_zodiac_2.webp')"
      class="w-100 d-flex mx-auto ct-cursor-zoom"
      style="max-width: 800px"
      @click="previewImage(2)"
    />

    <hr />
    <p>
      3. เมื่อกดบันทึกแล้ว จะขึ้นภาพราศีทั้งหมด รวมถึงวันและเวลาล่าสุดในการอัพเดท หากสถานะรีวิว ขึ้นว่า อนุมัติ
      นั่นหมายถึงสามารถนำไปใช้งานเพื่อสร้างรูปได้ สามารถอัปโหลดให้ครบทั้ง 12 ราศีที่ต้องการ
      เพื่อนำไปใช้งานร่วมกับภาพวอลเปเปอร์ที่ต้องการเปิดให้ดาวน์โหลดได้
      <b-img
        :src="require('@/assets/images/ongphra/how-to/upload_zodiac_3.webp')"
        class="w-100 d-flex mx-auto mt-2 ct-cursor-zoom"
        style="max-width: 800px"
        @click="previewImage(3)"
      />
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    previewImage(id = '') {
      this.$viewerApi({
        // eslint-disable-next-line import/no-dynamic-require, global-require
        images: [require(`@/assets/images/ongphra/how-to/upload_zodiac_${id}.webp`)],
        options: {
          navbar: false,
          title: false,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
