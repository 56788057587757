<template>
  <div style="margin-bottom: 120px;">
    <h4>คู่มือการใช้งาน Ongphra Wallpaper Creator</h4>
    <app-collapse type="margin" class="mt-2">
      <app-collapse-item title="วิธีอัพโหลดวอลเปเปอร์">
        <how-to-upload-wallpaper />
      </app-collapse-item>

      <app-collapse-item title="วิธีอัพโหลดราศี">
        <how-to-upload-zodiac />
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HowToUploadWallpaper from './components/HowToUploadWallpaper.vue'
import HowToUploadZodiac from './components/HowToUploadZodiac.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    HowToUploadWallpaper,
    HowToUploadZodiac,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped></style>
